import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-bacd9270"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "PiChange" }
const _hoisted_2 = { style: {"overflow-y":"hidden"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_Order = _resolveComponent("Order")!
  const _component_el_tabs = _resolveComponent("el-tabs")!
  const _component_CardForm = _resolveComponent("CardForm")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_form, {
      ref: "formRef",
      model: _ctx.form,
      rules: _ctx.rules,
      "label-width": "100px"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_CardForm, {
          ownerInst: _ctx.proxy,
          formItems: _ctx.compParams.formItems,
          form: _ctx.form,
          formRef: _ctx.formRef,
          rules: _ctx.rules,
          disabled: _ctx.disabled,
          refMap: _ctx.refMap,
          colNum: 2
        }, {
          extendSlot: _withCtx(() => []),
          default: _withCtx(() => [
            _createVNode(_component_el_tabs, {
              modelValue: _ctx.otherParams.activeName,
              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.otherParams.activeName) = $event))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_tab_pane, {
                  label: "基本信息",
                  name: "baseInfo"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_row, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_col, { span: 12 }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_form_item, {
                              label: "变更PI",
                              prop: "piId"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_el_select, {
                                  ref: el=>_ctx.refMap.set('piId',el),
                                  modelValue: _ctx.form.piId,
                                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.piId) = $event)),
                                  placeholder: "请选择要变更的PI",
                                  "value-key": "value",
                                  style: {"width":"100%"},
                                  disabled: _ctx.disabled,
                                  onChange: _cache[1] || (_cache[1] = (val)=>{_ctx.selectOnChange(val,'piId')})
                                }, {
                                  default: _withCtx(() => [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.comboSelect({comboId:'piId'}), (item) => {
                                      return (_openBlock(), _createBlock(_component_el_option, {
                                        key: item.value,
                                        label: item.label,
                                        value: item,
                                        disabled: item.disabled
                                      }, null, 8, ["label", "value", "disabled"]))
                                    }), 128))
                                  ]),
                                  _: 1
                                }, 8, ["modelValue", "disabled"])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_el_col, { span: 12 }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_form_item, {
                              label: "交货日期",
                              prop: "jhrq"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_el_date_picker, {
                                  modelValue: _ctx.form.jhrq,
                                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.jhrq) = $event)),
                                  placeholder: "请选择交货日期",
                                  disabled: _ctx.disabled
                                }, null, 8, ["modelValue", "disabled"])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_row, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_col, { span: 24 }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_form_item, {
                              label: "运输方式",
                              prop: "ysfs"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_el_input, {
                                  onInput: _cache[3] || (_cache[3] = e => _ctx.form.ysfs = _ctx.valid(e)),
                                  modelValue: _ctx.form.ysfs,
                                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.form.ysfs) = $event)),
                                  placeholder: "请输入运输方式",
                                  maxlength: "250",
                                  disabled: _ctx.disabled,
                                  clearable: ""
                                }, null, 8, ["modelValue", "disabled"])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_row, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_col, { span: 24 }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_form_item, {
                              label: "付款条款",
                              prop: "fktk"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_el_input, {
                                  onInput: _cache[5] || (_cache[5] = e => _ctx.form.fktk = _ctx.valid(e)),
                                  modelValue: _ctx.form.fktk,
                                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.form.fktk) = $event)),
                                  type: "textarea",
                                  placeholder: "请输入付款条款",
                                  disabled: _ctx.disabled,
                                  clearable: ""
                                }, null, 8, ["modelValue", "disabled"])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_row, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_col, { span: 24 }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_form_item, {
                              label: "价格条款",
                              prop: "jgtk"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_el_input, {
                                  onInput: _cache[7] || (_cache[7] = e => _ctx.form.jgtk = _ctx.valid(e)),
                                  modelValue: _ctx.form.jgtk,
                                  "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.form.jgtk) = $event)),
                                  type: "textarea",
                                  placeholder: "请输入价格条款",
                                  disabled: _ctx.disabled,
                                  clearable: ""
                                }, null, 8, ["modelValue", "disabled"])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                (_ctx.form.piId)
                  ? (_openBlock(), _createBlock(_component_el_tab_pane, {
                      key: 0,
                      label: "订单信息",
                      name: "orderInfo"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_2, [
                          _createVNode(_component_Order, {
                            ref: el=>_ctx.refMap.set('order',el),
                            priceFlag: _ctx.otherParams.order.priceFlag,
                            sumAmountFlag: _ctx.otherParams.order.sumAmountFlag,
                            numEditFlag: _ctx.otherParams.order.numEditFlag,
                            pccFlag: _ctx.otherParams.order.pccFlag,
                            otherInfoFlag: _ctx.otherParams.order.otherInfoFlag,
                            addBtnFlag: _ctx.otherParams.order.addBtnFlag,
                            disableCustomer: _ctx.otherParams.order.disableCustomer,
                            customerDelivery: _ctx.otherParams.order.customerDelivery,
                            disablePcc: _ctx.otherParams.order.disablePcc,
                            disablePrice: _ctx.otherParams.order.disablePrice,
                            disableOtherInfo: _ctx.otherParams.order.disableOtherInfo,
                            showHis: _ctx.otherParams.order.showHis
                          }, null, 8, ["priceFlag", "sumAmountFlag", "numEditFlag", "pccFlag", "otherInfoFlag", "addBtnFlag", "disableCustomer", "customerDelivery", "disablePcc", "disablePrice", "disableOtherInfo", "showHis"])
                        ])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["modelValue"])
          ]),
          _: 1
        }, 8, ["ownerInst", "formItems", "form", "formRef", "rules", "disabled", "refMap"])
      ]),
      _: 1
    }, 8, ["model", "rules"])
  ]))
}